import React from "react"
import * as Icon from "react-feather"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Four Phased Approach "
const pageTitle = "Four Phased Approach"
const subTitle = "Cloud"
const para =
  "Are you looking to migrate out of your data center or to modernize your applications but have too many questions keeping you back? Take a look at our roadmap aimed at giving you the confidence to modernize."
const blockquote = `"Take existing data and applications and rehost them in the cloud."`

const cardItems = [
  {
    id: 1,
    heading: `Phase One – Assessment`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Assess the current state of your cloud implementation identify gaps
          and recommend a target state strategy.
        </li>
        <li>
          <Icon.Square />
          Prioritize recommendations, define the administration of the cloud and
          target state cloud architecture.
        </li>
      </>
    ),
  },
  {
    id: 2,
    heading: `Phase Two -Standardize`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Standardize your cloud environment (Landing Zone design, define
          guardrail/governance policies, CI/CD workflows, etc.).
        </li>
        <li>
          <Icon.Square />
          Define your network/security architecture & standards, SRE, tooling &
          image management approach. Define and begin to implement account/
          financial management approach and related tools.
        </li>
      </>
    ),
  },
  {
    id: 3,
    heading: `Phase Three - Automate`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Automate your cloud environment (Landing Zones, Policies, IAM,
          DevSecOps, Tooling, etc.) Define key metrics, Cloud Infrastructure &
          cloud adoption program.
        </li>
        <li>
          <Icon.Square />
          Establish Tiger Teams to assist development teams with migrations.
          Develop common migration patterns.
        </li>
      </>
    ),
  },
  {
    id: 4,
    heading: `Phase Four - Transform`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Transform your organization.Accelerate the migration of your
          applications to the cloud. {" "}
        </li>
        <li>
          <Icon.Square /> Evangelize new standards, processes, procedures and
          tools working towards cultural adoption.  Advance your operating model
          and measure and report your cloud transformation success.
        </li>
      </>
    ),
  },
]
const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query CloudQuery {
    casedetailsJson(slug: { eq: "four-phased-approach" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
